const shajs = require('sha.js'),
    nacl = require('tweetnacl')

const base32Alphabet = {};
('ABCDEFGHIJKLMNOPQRSTUVWXYZ234567').split('').map((c, i) => base32Alphabet[c] = i)

function decodeBase32(input) {
    const buf = []
    let shift = 8,
        carry = 0

    for (let char of input.split('')) {
        const symbol = base32Alphabet[char] & 0xff
        shift -= 5
        if (shift > 0) {
            carry |= symbol << shift
        } else if (shift < 0) {
            buf.push(carry | (symbol >> -shift))
            shift += 8
            carry = (symbol << shift) & 0xff
        } else {
            buf.push(carry | symbol)
            shift = 8
            carry = 0
        }
    }

    if (shift !== 8 && carry !== 0) {
        buf.push(carry)
        shift = 8
        carry = 0
    }

    return buf
}


/**
 * Verify ED25519 message signature generated by Albedo signer app.
 * @param {String} publicKey - Ed25519 public key in StrKey encoding ("GDWP...N4LO").
 * @param {String} message - Arbitrary text message signed by the given public key.
 * @param {String|Buffer|Uint8Array} signature - Hex-encoded message signature.
 * @return {Boolean} - Returns true if a signature is valid and false otherwise.
 */
function verifyMessageSignature(publicKey, message, signature) {
    if (!/^G[ABCDEFGHIJKLMNOPQRSTUVWXYZ234567]{55}$/.test(publicKey))
        throw new Error('Invalid public key format. StrKey-encoded public key expected.')
    if (typeof message !== 'string' || !message.length)
        throw new Error('Invalid message format. UTF8 string expected.')
    if (typeof signature === 'string') {
        if (!/^[a-f\d]{128}$/.test(signature))
            throw new Error('Invalid signature format. Hex-encoded ED25519 signature expected.')
        signature = new Uint8Array(signature.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))
    }
    if (!signature || signature.length !== 64)
        throw new Error('Invalid signature length. ED25519 signature should be exactly 64 bytes in length.')

    if (message.indexOf(publicKey + ':') !== 0) {
        message = publicKey + ':' + message
    }
    try {
        const encodedMessage = shajs('sha256').update(message).digest()
        return nacl.sign.detached.verify(encodedMessage, signature, new Uint8Array(decodeBase32(publicKey).slice(1, -2)))
    } catch (e) {
        throw new Error('Unhandled error. The input is malformed. Failed to verify the signature.')
    }
}

module.exports = {verifyMessageSignature}